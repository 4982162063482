<template>
    <div class="aldeasInteligentes">
        <!-- CONTENIDO -->
        <div class="container">
            <!-- BREADCRUM + LOGOTIPO + DATOS DE USUARIO -->
            <div class="row top-buffer">
                <div class="col-md-12">
                    <ol class="breadcrumb">
                        <li><a href="https://www.gob.mx"><i class="icon icon-home"></i></a></li>
                        <li><a href="https://www.gob.mx/sct">SCT</a></li>
                        <li>Portal Cobertura Universal</li>
                        <li class="active">Aldeas inteligentes, Bienestar sostenible (Mapa Interactivo)</li>
                    </ol>
                </div>
            </div>
            <div class="row">
                <div class="col-md-7" id="logotipo">
                    <img src="../assets/img/logo.png" alt="Secretaría de Comunicaciones y Transportes" />
                </div>
            </div>

            <!-- TÍTULO Y SUBTÍTULO -->
            <div class="row">
                <div class="col-md-12">
                    <h1 style="margin-bottom:30px;">Aldeas inteligentes, Bienestar sostenible (Mapa Interactivo)</h1>
                    <hr class="red">
                </div>
            </div>

            <!-- CONTENIDO -->
            <div id="map_test"></div>

            <div class="row">
                <div class="col-md-12 text-right">
                    <hr />
                    <a class="btn btn-default" type="button" href="/">Regresar</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from "../router"
import axios from "axios"

import "leaflet/dist/leaflet.css";
import L from "leaflet";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
   iconUrl: require('leaflet/dist/images/marker-icon.png'),
   shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
    name: 'aldeasInteligentes',
    components: {},
    data() {
        return {
            center: [19.60492, -99.06064],
            items: []
        };
    },
    created() {
        this.getAIIS();
    },
    methods: {
        back(){
            router.replace('/')
        },
        async getAIIS() {
            try {
                let response = await axios.get(process.env.VUE_APP_BASE_URL+'/aldeas-inteligentes/')
                this.items = response.data
            } catch (error) {
            }
            this.setupLeafletMap()
        },
        getHtmlDiv: function(item) {
            var html_div = '<div id="html_'+item.gid+'" style="width: 100.0%; height: 100.0%;"> \
                <table border="1"> \
                    <thead></thead> \
                    <tbody> \
                        <tr> \
                            <th style="color:#f1faee;background-color:#235B4E;padding: 8px" >Nombre Unidad: </th> \
                            <td style="padding: 8px" > '+item.unidad+'</td> \
                        </tr> \
                        <tr> \
                            <th style="color:#f1faee;background-color:#235B4E;padding: 8px" >GID: </th> \
                            <td style="padding: 8px" > '+item.gid+'</td> \
                        </tr> \
                        <tr> \
                            <th style="color:#f1faee;background-color:#235B4E;padding: 8px" >Denominación: </th> \
                            <td style="padding: 8px" > '+item.denominacion+'</td> \
                        </tr> \
                        <tr> \
                            <th style="color:#f1faee;background-color:#235B4E;padding: 8px" >Estado: </th> \
                            <td style="padding: 8px" > '+item.estado+'</td> \
                        </tr> \
                        <tr> \
                            <th style="color:#f1faee;background-color:#235B4E;padding: 8px" >Municipio: </th> \
                            <td style="padding: 8px" > '+item.municipio+'</td> \
                        </tr> \
                        <tr> \
                            <th style="color:#f1faee;background-color:#235B4E;padding: 8px" >Localidad: </th> \
                            <td style="padding: 8px" > '+item.localidad+'</td> \
                        </tr> \
                        <tr> \
                            <th style="color:#f1faee;background-color:#235B4E;padding: 8px" >Localización: </th> \
                            <td style="padding: 8px" > '+item.localizacion+'</td> \
                        </tr> \
                        <tr> \
                            <th style="color:#f1faee;background-color:#235B4E;padding: 8px" >Lengua Indígena: </th> \
                            <td style="padding: 8px" > '+item.lengua_indigena+'</td> \
                        </tr> \
                        <tr> \
                            <th style="color:#f1faee;background-color:#235B4E;padding: 8px" >Fecha Instalación: </th> \
                            <td style="padding: 8px" > '+item.fecha_instalacion+'</td> \
                        </tr> \
                        <tr> \
                            <th style="color:#f1faee;background-color:#235B4E;padding: 8px" >Página sitio: </th> \
                            <td style="padding: 8px" > \
                                '+item.pagina_sitio+' \
                            </td> \
                        </tr> \
                    </tbody> \
                </table> \
            </div>';
            return html_div;
        },
        setMarker: function(item) {
            var greenHomeIcon = L.Icon.extend({
                options: {
                    iconUrl: require('../assets/img/icons/green_home_icon.png'),
                    iconSize: [25, 41],
                    iconAnchor: [12, 41]
                }
            });
            var ghi = new greenHomeIcon()

            var marker = L.marker(
                [item.latitud, item.longitud],
                {icon: ghi}
            );

            var popup = L.popup({"maxWidth": 450});

            var html = $(this.getHtmlDiv(item))[0];
            popup.setContent(html);
            marker.bindPopup(popup);
            marker.bindTooltip('<div> más información </div>', {"sticky": true});

            return marker;
        },
        setupLeafletMap: function () {
            const mapDiv = L.map("map_test").setView(this.center, 5);
            L.tileLayer(
                "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
                {
                    attribution: 'Map data (c) <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery (c) <a href="https://www.mapbox.com/">Mapbox</a>',
                    maxZoom: 19,
                    minZoom: 5,
                    id: "mapbox/streets-v11",
                    accessToken:"pk.eyJ1IjoiZGFuaWVsbWFydGluZXo4OCIsImEiOiJja3dtcTFkangyZWdkMnZxaWh4bmMyZ3RkIn0.ebbwh5reCbQ0Rd-zZtvgdA",
                }
            ).addTo(mapDiv);

            for (let index = 0; index < this.items.length; index++) {
                const element = this.items[index];
                this.setMarker(element).addTo(mapDiv);
            }
        },
    }
}
</script>

<style scoped>
    #map_test {
        width: 80vw;
        height: 100vh;
    }
</style>
